import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Select,
  Radio,
  Form,
  Upload,
  message,
  InputNumber,
  DatePicker
} from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUploadCloud } from "react-icons/fi";
import Places from "./Places";
import "../../../src/index.css";
import { AppContext } from "../../context/AppContextProvider";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { TextArea } = Input;

const AddNewGrievance = ({
  addGrievanceModal,
  setAddGrievanceModal,
  setActiveTab = () => {},
  userData
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const axiosInstance = useAxiosInstance();
  const { user } = useContext(AppContext); // keep this for imageUpload request
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState(1);
  const [parentId, setParentId] = useState(null);
  const [uploadImageData, setUploadImageData] = useState([]);
  const [locationData, setLocationData] = useState("");
  const [newGrievanceData, setNewGrievanceData] = useState({
    constituencyId: user?.constituencyId,
    officeId: null,
    visitorType: "",
    numberOfPeople: "",
    firstName: "",
    lastName: "" || " ",
    gender: "",
    email: "",
    phone: "",
    categoryId: null,
    categoryName: "",
    subCategoryId: null,
    subCategoryName: "",
    grievanceTitle: "No Title",
    location: {},
    uploadImages: [],
    priority: "",
    description: ""
  });

  const onInputChange = (e) => {
    setNewGrievanceData({
      ...newGrievanceData,
      [e.target.name]: e.target.value
    });
  };

  //Fetch Category List-->
  const fetchCategoryList = async () => {
    const response = await axiosInstance.get(
      `api/category/main?size=${100}&page=${1}&sort=${"DESC"}`
    );
    return response;
  };
  const categoryList = useQuery("Category-List", fetchCategoryList, {
    refetchOnWindowFocus: false,
    retry: 1
  });

  //Fetch Sub-Category List-->
  const fetchSubCategoryList = async (parentId) => {
    const response = await axiosInstance.get(
      `api/category/sub?size=${100}&page=${1}&sort=${"DESC"}&parentId=${parentId}`
    );
    return response;
  };

  const subCategoryList = useQuery(
    "Sub-Category-List",
    () => fetchSubCategoryList(parentId),
    { refetchOnWindowFocus: false, retry: 1 }
  );

  // <--
  //populate subcategory data-->
  const handleSubCat = (id) => {
    setParentId(id);
  };
  // <--

  //add new Grievance-->
  const addGrievance = async () => {
    return axiosInstance.post(`/api/grievances/createGrievance`, {
      ...newGrievanceData,
      grievanceTitle: newGrievanceData.grievanceTitle
        ? newGrievanceData.grievanceTitle
        : "No Title"
    });
  };

  const mutateAddGrievance = useMutation(addGrievance, {
    onSuccess: (data) => {
      toast.success(
        `${
          data?.data?.message.charAt(0).toUpperCase() +
          data?.data?.message.slice(1)
        }`
      );
      //invalidate cache and refresh
      queryClient.invalidateQueries("GMS-List_Data");
      queryClient.invalidateQueries("gmsSummary");
      queryClient.invalidateQueries("gmsStatus");
      setAddGrievanceModal(false);
      setActiveTab("list");
    },
    onError: (error) => {
      toast.error(
        `something went wrong: ${error?.response?.data?.error?.message
          .split(":")
          .pop()
          .trim()}`
      );
    }
  });
  // <--

  //upload images-->
  const uploadImages = async (resourceLocation) => {
    const formData = new FormData();
    formData.append("grievanceImages", resourceLocation);
    return axiosInstance
      .post("api/grievances/uploadGrievanceImages", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then((res) => {
        setUploadImageData((prevState) => [...prevState, res?.data?.data?.[0]]);
        message.success(`${resourceLocation?.name} file uploaded successfully`);
      })
      .catch((err) =>
        message.error(
          `${resourceLocation?.name} file upload failed: ${err?.message}`
        )
      );
  };
  // <--

  const handleDeleteImage = (index) => {
    const updatedImages = uploadImageData.filter(
      (image, imageIndex) => imageIndex !== index
    );
    setUploadImageData(updatedImages);
  };

  const handleSubmit = (e) => {
    if (!mutateAddGrievance.isLoading) {
      mutateAddGrievance.mutateAsync();
    }
  };
  const handleUploadImages = async (e) => {
    await uploadImages(e?.file);
  };

  //modal functions-->
  const handleCancel = () => {
    setAddGrievanceModal(false);
  };
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const preventFormSubmissionOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  // <--

  //updating "newGrievanceData" state
  useEffect(() => {
    setNewGrievanceData((prevState) => ({
      ...prevState,
      uploadImages: uploadImageData
    }));
  }, [uploadImageData]);

  //updating "locationData" state
  useEffect(() => {
    setNewGrievanceData((prevState) => ({
      ...prevState,
      location: locationData
    }));
  }, [locationData]);

  useEffect(() => {
    if (parentId) {
      subCategoryList.refetch();
    }
  }, [parentId]);

  // multi-step render form
  const renderStepOne = () => (
    <Form form={form} onFinish={handleNext}>
      <ToastContainer />
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("Add New Grievance")}</p>
        </div>
        <div className=''>
          <div className='modal-body'>
            <h3>{t("Issuer Details")}</h3>
            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>
                  {t("Visitor Type")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='visitorType'
                  rules={[
                    {
                      required: true,
                      message: t("Please enter the Visitor Type")
                    }
                  ]}
                >
                  <Select
                    placeholder={t("Enter Visitor Type")}
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='visitorType'
                    required
                    onChange={(value) =>
                      setNewGrievanceData({
                        ...newGrievanceData,
                        visitorType: value
                      })
                    }
                    options={[
                      {
                        value: "citizen",
                        label: t("citizen")
                      },
                      {
                        value: "vip",
                        label: t("vip")
                      },
                      {
                        value: "cadre",
                        label: t("cadre")
                      },
                      {
                        value: "other",
                        label: t("other")
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>{t("Number of people")}</label>
                <Form.Item
                  name='numberOfPeople'
                  onChange={(e) => onInputChange(e)}
                >
                  <InputNumber
                    name='numberOfPeople'
                    placeholder={t("Enter no. of people")}
                    style={{ marginTop: "10px", width: "100%" }}
                    min={0}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>
                  {t("First Name")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: t("Please enter the first name")
                    }
                  ]}
                >
                  <Input
                    placeholder='Enter First Name'
                    name='firstName'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>{t("Last Name")}</label>
                <Form.Item
                  name='lastName'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t("Please enter the last name")
                  //   }
                  // ]}
                >
                  <Input
                    placeholder='Enter Last Name'
                    name='lastName'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    // required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>
                  {t("Gender")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='gender'
                  rules={[
                    {
                      required: true,
                      message: t("Please select the gender")
                    }
                  ]}
                >
                  <Select
                    placeholder={t("Select Gender")}
                    required
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='gender'
                    onChange={(value) =>
                      setNewGrievanceData({
                        ...newGrievanceData,
                        gender: value
                      })
                    }
                    options={[
                      {
                        value: "male",
                        label: t("male")
                      },
                      {
                        value: "female",
                        label: t("female")
                      },
                      {
                        value: "others",
                        label: t("others")
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>{t("Email")}</label>
                <Form.Item
                  name='email'
                  rules={[
                    // { required: true, message: t("Please Enter the Email") },
                    {
                      type: "email",
                      message: t("Please enter a valid email address")
                    }
                  ]}
                >
                  <Input
                    id='email'
                    name='email'
                    placeholder={t("john.smith@abc.com")}
                    className='mt2'
                    style={{ marginTop: "10px" }}
                    onChange={(e) => onInputChange(e)}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>
                  {t("Phone")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='phone'
                  rules={[
                    {
                      required: true,
                      message: t("Please Enter the Phone No.")
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: t("Please enter a valid 10-digit phone number")
                    }
                  ]}
                >
                  <Input
                    type='tel'
                    required
                    addonBefore='+91'
                    id='phone'
                    name='phone'
                    placeholder={t("9876543210")}
                    className='mt2 '
                    style={{ marginTop: "10px" }}
                    onChange={(e) => onInputChange(e)}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>{t("dueDate")}</label>
                <Form.Item name='dueDate'>
                  <DatePicker
                    name='dueDate'
                    id='dueDate'
                    placeholder='DD/MM/YY'
                    className='mt2'
                    style={{ marginTop: "10px" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    disabled={[
                      "data_entry_operator",
                      "office_manager"
                    ].includes(userData?.role)}
                    onChange={(date, dateString) => {
                      setNewGrievanceData((prev) => ({
                        ...prev,
                        dueDate: dateString
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div className='btn-flex'>
          <Button
            type='button'
            className='primary-btn mr3 mt3 mb3'
            htmlType='submit'
          >
            {t("Next")}
          </Button>
        </div>
      </div>
    </Form>
  );

  const renderStepTwo = () => (
    <Form form={form} onFinish={handleNext}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("Add New Grievance")}</p>
        </div>
        <div className='modal-body-container'>
          <div className='modal-body'>
            <h3>{t("Issue Details")}</h3>
            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>
                  {t("Category")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='categoryName'
                  rules={[
                    {
                      required: true,
                      message: t("Please select the Category")
                    }
                  ]}
                >
                  <Select
                    placeholder={t("Enter Category")}
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='categoryName'
                    onChange={(value, key) => {
                      setNewGrievanceData({
                        ...newGrievanceData,
                        categoryName: value
                      });
                      setNewGrievanceData((prevState) => ({
                        ...prevState,
                        categoryId: key?.key
                      }));
                      handleSubCat(key?.key);
                    }}
                  >
                    {categoryList?.data?.data?.data?.map((category) => (
                      <Select.Option key={category._id} value={category.name}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>
                  {t("Sub-category")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='subCategoryName'
                  rules={[
                    {
                      required: true,
                      message: t("Please select the Sub-Category")
                    }
                  ]}
                >
                  {parentId ? ( // Render the Select field only if parentId is present
                    <Select
                      placeholder={t("Enter Sub-Category")}
                      size='large'
                      style={{ marginTop: "10px" }}
                      name='subCategoryName'
                      onChange={(value, key) => {
                        setNewGrievanceData({
                          ...newGrievanceData,
                          ["subCategoryName"]: value
                        });
                        setNewGrievanceData((prevState) => ({
                          ...prevState,
                          subCategoryId: key.key
                        }));
                      }}
                    >
                      {subCategoryList?.data?.data?.data?.map((subCategory) => (
                        <Select.Option
                          key={subCategory._id}
                          value={subCategory.name}
                        >
                          {subCategory.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    // Render a disabled input or placeholder text when parentId is not present
                    <Input
                      disabled
                      placeholder={t("Select Category first")}
                      style={{ marginTop: "10px" }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={9}>
                <label htmlFor=''>{t("Title")}</label>
                <Form.Item
                  name='grievanceTitle'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter the Title"
                  //   }
                  // ]}
                >
                  <Input
                    name='grievanceTitle'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    // required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={9} offset={1}>
                <label htmlFor=''>
                  {t("Priority")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='priority'
                  rules={[
                    {
                      required: true,
                      message: t("Please select the Priority")
                    }
                  ]}
                >
                  <Radio.Group
                    style={{ display: "block", marginTop: "25px" }}
                    name='priority'
                    onChange={(e) => onInputChange(e)}
                    required
                  >
                    <Radio value='low'>{t("Low")}</Radio>
                    <Radio value='medium'>{t("Medium")}</Radio>
                    <Radio value='high'>{t("High")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={19}>
                <label htmlFor=''>
                  {t("Description")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: t("Please enter the Description")
                    }
                  ]}
                >
                  <TextArea
                    rows={2}
                    required
                    id='description'
                    name='description'
                    onChange={(e) => onInputChange(e)}
                    style={{ marginTop: "8px", width: "97%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='gms-img-upload'>
              <Col span={19}>
                <label htmlFor=''> {t("Upload Images")}</label>
                <Form.Item name='uploadImages'>
                  <Upload.Dragger
                    name='uploadImages'
                    beforeUpload={() => false}
                    onChange={handleUploadImages}
                    className='custom-img-upload'
                    style={{ marginTop: "8px" }}
                    accept='.jpg, .png, .pdf'
                    maxFileSize={10 * 1024 * 1024}
                    type='file'
                    fileList={[]}
                  >
                    <FiUploadCloud
                      style={{ color: "#004877", fontSize: "50px" }}
                    />
                    <p className='ant-upload-text'>
                      {t("Select a file or drag and drop here")}
                      <br />
                      <span>
                        {t("JPG, PNG, or PDF, file size no more than 10MB")}
                      </span>
                    </p>
                    <Button className='ImgUpload'>{t("Select File")}</Button>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>

            <Row className='gms-uploaded-images-div'>
              {newGrievanceData?.uploadImages?.map((image, index) => (
                <Col span={4} key={index}>
                  <div className='image-box'>
                    <div
                      className='delete-icon'
                      onClick={() => handleDeleteImage(index)}
                    >
                      <i className='fas fa-trash-alt'></i>
                    </div>
                    <img
                      src={image?.publicUrl}
                      alt={image?.name}
                      className='uploaded-image_item'
                      onClick={() => window.open(image?.publicUrl, "_blank")}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>

      <div className='btn-flex1'>
        <Button
          type='primary'
          className='prev-button ml3 mt3 mb3'
          onClick={handleBack}
        >
          {t("Previous")}
        </Button>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
        >
          {t("Next")}
        </Button>
      </div>
    </Form>
  );

  const renderStepThree = () => (
    <Form form={form} onFinish={handleSubmit}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("Add New Grievance")}</p>
        </div>
        <div className='modal-body'>
          <h3>
            {t("Location")}
            <span className='required-field'>*</span>
          </h3>
          <Places setLocationData={setLocationData} />
        </div>
      </div>
      <div className='btn-space'>
        <Button
          type='primary'
          className='prev-button ml3 mt3 mb3'
          onClick={handleBack}
        >
          {t("Previous")}
        </Button>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
          // onClick={() => handleSubmit()}
        >
          {t("Add New Grievance")}
        </Button>
      </div>
    </Form>
  );
  return (
    <Modal
      className='modal-container'
      footer={null}
      open={addGrievanceModal}
      onCancel={handleCancel}
      width={1000}
      maskClosable={false}
    >
      <div className='gms-modal'>
        {currentStep === 1
          ? renderStepOne()
          : currentStep === 2
          ? renderStepTwo()
          : renderStepThree()}
      </div>
    </Modal>
  );
};

export default AddNewGrievance;
