import React, { useMemo, useState, useEffect } from "react";

import { Select } from "antd";
// import { MdAdd } from "react-icons/md";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import ConstituencyContext from "../../context/constituencyContext";
import ConstituencyInfo from "./ConstituencyInfo";
import { useTranslation } from "react-i18next";
import VillageData from "./VillageInfo";

import ConstituencyData from "./ConstituencyInformation";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useQuery } from "react-query";

const Constituency = ({ constituencyData, loading }) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();

  const [selectedMarker, setSelectedMarker] = useState({});
  const [electionYears, setElectionYears] = useState([]);
  // const [yearSelected, setYearSelected] = useState("");
  const [year, setYear] = useState();
  const constituencyContext = useContext(ConstituencyContext);
  const handleModal = () => {
    constituencyContext.updateOpenModal(true);
  };

  const constituencyId = constituencyData ? constituencyData._id : null;

  const getIsDataAvailable = (summaryData) => {
    const checkAvailability = (key) =>
      ["YES", "Yes"].includes(summaryData[key]);

    return (
      checkAvailability("Form 20") &&
      checkAvailability("Electoral Roll") &&
      checkAvailability("PS Village Mapping Data") &&
      checkAvailability("Village Data")
    );
  };
  // API request function
  const fetchConstituencyByState = async (id, year) => {
    return axiosInstance.get(
      `api/constituency/getConstituencySummaryById/${id}?year=${year}`
    );
  };

  // Query using react-query
  const queryData = useQuery(
    ["summary-data", constituencyId, year],
    () => {
      if (constituencyId && year) {
        return fetchConstituencyByState(constituencyId, year);
      } else {
        // Return a resolved promise with null data
        return Promise.resolve(null);
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  // Access the data from the query response
  const summaryData = queryData?.data?.data?.data || {};
  const isDataAvailable = getIsDataAvailable(summaryData);

  const fetchConstituencyTableById = async (constituencyId, year) => {
    if (constituencyId && year) {
      // Check if both constituencyId and year are defined
      return axiosInstance.get(
        `api/constituency/getConstituencyTableById/${constituencyId}?years=${year}`
      );
    } else {
      return null;
    }
  };

  const constituencyTableData = useQuery(
    ["client-constituency-table", constituencyId, year],
    () => fetchConstituencyTableById(constituencyId, year),
    {
      enabled: constituencyId !== undefined && year !== undefined,
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const constituencyDataList = constituencyTableData?.data?.data?.data;
  const constituencyLoading = constituencyTableData?.isLoading || true;

  const center = useMemo(() => {
    return (constituencyTableData?.data?.data?.data?.villageData || []).map(
      (village) => {
        if (village !== null && village?.location != null) {
          const parts = village?.location.split(", ");

          // Extract latitude and longitude values
          const lat = parseFloat(parts[0].split(":")[1].trim());
          const long = parseFloat(parts[1].split(":")[1].trim());

          return {
            lat: +lat,
            lng: +long,
            title: village?.villageName,
            village_id: village?._id,
            winnerParty: village?.winnerParty,
            runnerUpParty: village?.runnerUpParty,
            firstRunnerUpParty: village?.firstRunnerUpParty
          };
        } else if ((village !== null && village?.location) === null) {
          return {
            lat: 28.7041,
            lng: 77.1025, //delhi coordinate if lat lng not available
            title: village?.villageName,
            village_id: village?._id,
            winnerParty: village?.winnerParty,
            runnerUpParty: village?.runnerUpParty,
            firstRunnerUpParty: village?.firstRunnerUpParty
          };
        } else {
          return null;
        }
      }
    );
  }, [constituencyTableData]);

  useEffect(() => {
    if (constituencyId) {
      const fetchElectionYears = async () => {
        try {
          const response = await axiosInstance.get(
            `api/constituency/getElectionYears/${constituencyId}`
          );
          setElectionYears(response.data);
          setYear(response.data?.data?.electionYears[0]);
        } catch (error) {
          console.error(error);
        }
      };

      fetchElectionYears();
    }
  }, [constituencyId]);

  const fetchVillageTableById = async (id, year) => {
    return axiosInstance.get(
      `api/constituency/getVillageTableById/${id}?years=${year}`
    );
  };

  const queryVillageData = useQuery(
    ["village-data", selectedMarker?.village_id, year],
    () => fetchVillageTableById(selectedMarker?.village_id, year),
    {
      refetchOnWindowFocus: false,
      enabled: selectedMarker?.village_id !== undefined && year !== undefined,
      retry: 1
    }
  );

  const villageLoading = queryVillageData?.isLoading || true;

  const villageFetchedData = queryVillageData?.data?.data?.data;
  const villagesList = constituencyTableData?.data?.data?.data?.villageData;

  const handleVillageSelectChange = (id) => {
    const newMarker = center.find((item) => item?.village_id === id);
    if (newMarker) {
      setSelectedMarker(newMarker);
    }
  };

  useEffect(() => {
    if (center.length > 0) {
      setSelectedMarker(center[0]);
    }
  }, [JSON.stringify(center)]);

  return (
    <>
      <div className='view-constituency'>
        <div className='flex items-center justify-between mt-5'>
          <div className='constituency-name'>
            <p>
              {constituencyData?.name || "NA"}{" "}
              <span className='mr-2 pl-1'> ({t("assembly")}) {constituencyData?.district || "NA"},
                <span> {constituencyData?.state || "NA"} </span>
              </span>
              <InfoCircleOutlined onClick={handleModal} />
            </p>
          </div>

          <div className='flex items-center mt-5'>
            <Select
              id='villageSelect'
              placeholder={t("select") + " " + t("villages")}
              value={selectedMarker?.village_id}
              onChange={handleVillageSelectChange}
              className='mr-4'
              showSearch // Enable showSearch
              optionFilterProp='children' // Specify the property to filter on
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(villagesList || [])
                .sort((a, b) => a.villageName.localeCompare(b.villageName)) // Sort the array alphabetically
                .map((village) => (
                  <Select.Option key={village?._id} value={village?._id}>
                    {village?.villageName}
                  </Select.Option>
                ))}
            </Select>

            <Select
              className='mr-4'
              placeholder={t("selectYear")}
              onChange={(value) => setYear(value)}
              value={year}
            >
              {electionYears?.data?.electionYears.map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>

            {/* <button className="rounded-lg" onClick={handleAddYear}> <MdAdd /> {t("addYear")} </button> */}
          </div>
        </div>
        <div className='flex mb-3 constituency-count'>
          <p className='mr-5'>
            {t("villages")}:
            <span>{constituencyDataList?.totalVillageCount} </span>
          </p>
          <p>
            {t("pollingStations")}:
            <span> {constituencyDataList?.totalPSCount}</span>
          </p>
        </div>
        <VillageData
          constituencyData={constituencyData}
          loading={loading}
          summaryData={summaryData}
          constituencyDataList={constituencyDataList}
          center={center}
          setSelectedMarker={setSelectedMarker}
          selectedMarker={selectedMarker}
          villageFetchedData={villageFetchedData}
          isDataAvailable={isDataAvailable}
          isLoading={villageLoading}
        />
        <ConstituencyData
          summaryData={summaryData}
          constituencyDataList={constituencyDataList}
          isDataAvailable={isDataAvailable}
          isLoading={constituencyLoading}
        />
      </div>
      {constituencyContext.isOpenModal && (
        <ConstituencyInfo
          isOpen={constituencyContext.isOpenModal}
          constituencyData={constituencyData}
          constituencyDataList={constituencyDataList}
        />
      )}
    </>
  );
};

export default Constituency;
